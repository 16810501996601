@use "abstracts" as *;

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: $color-gray-light;
}
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: none;
  background-color: $color-gray;
}

*{
  box-sizing: border-box;
}
body{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 100%;
  overflow-x: hidden;

  &.no-scrolling {
    overflow-y: hidden;
  }
}

img{
  max-width: 100%;
}
