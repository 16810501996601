@use "abstracts" as *;

html {
  font-size: 16px;
  font-family: "Fira Sans", sans-serif;
  font-weight: $weight-regular;
  line-height: 1.375;
  word-break: break-word;
  hyphens: auto;
  width: 100%;
}



strong {
  font-weight: $weight-medium;
}

a {
  text-decoration: none;
  color: $primary-color;
  cursor: pointer;

  p & {
    &:hover,
    &:focus-visible {
      text-decoration: underline;
    }
  }

  &.blocklink {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

p {
  margin: 0;

  + p {
    margin-top: 1.5rem;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
}

h1 {
  font-size: 1.875rem;
  font-weight: $weight-bold;
}

i-feather {
  box-sizing: content-box;
  padding: 12px;
}

.highlight {
  color: $primary-color;
  font-weight: $weight-medium;
}

.with-tooltip {
  position: relative;
}

::-moz-selection {
  color: $color-white;
  background: $primary-color;
}

::selection {
  color: $color-white;
  background: $primary-color;
}

input,
select,
textarea{
  // prevent zoom-in from safari
  font-size: 16px;

  // prevent safari borders
  -webkit-border-radius: 0px;

  // prevent safari default hight
  -webkit-appearance: none;

  color: $color-black;
}
