// Colors
$color-white-light: rgb(242, 242, 242,20%);
$color-white: rgba(255,255,255,1);
$color-gray-light: rgba(241,241,241,1);
$color-gray: rgba(206,206,206,1);
$color-gray-dark: rgba(127,127,127,1);

$color-black: rgba(0,0,0,1);

$primary-color: rgba(210,10,17,1);

// Font
$weight-regular: 400;
$weight-medium: 500;
$weight-bold: 700;

// sizes
$header-height_mobile: 96px;

//containers
$container-width-inner : calc(35 * 1.5rem);


