/* position */
.toast-bottom-full-width {
  right: 0;
  bottom: 0;
  width: 100%;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

/* toast styles */
.toast-message {
  font-size: 0.9rem;
}

.toast-title {
  font-weight: bolder;
  margin-bottom: 0.9rem;
}

.toast-container {
  position: fixed;
  z-index: 999999;
  pointer-events: none;
}

.toast-container * {
  box-sizing: border-box;
  hyphens: manual;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  width: 350px;
  margin: 0 0 6px;
  padding: 15px 24px 15px 24px;
  color: #FFFFFF;
  border-radius: 2px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.26);

  &:hover {
    cursor: pointer;
  }
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-right: auto;
  margin-left: auto;
}

.ngx-toastr {
  display: block;
  pointer-events: auto;
  background-color: #323232;

  &.toast-warning {
    color: rgba(0,0,0,1);
    background-color: #FFC800;
  }

  &.toast-success {
    background-color: #008523;
  }
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    width: 11em;
    padding: 8px 8px 8px 50px;
  }

}

@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    width: 18em;
    padding: 8px 8px 8px 50px;
  }

}

@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    width: 25em;
    padding: 15px 15px 15px 50px;
  }
}
